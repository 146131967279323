import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAvailabilityLabels, getTagList } from "../../helpers/helpers";
import LinkInput from "./LinkInput";
import { UseFormReturn, Controller } from "react-hook-form";
import { AddFormValues } from "./AddContent";

interface ExistingContentProps {
  form: UseFormReturn<AddFormValues>;
  setOtherTags: any;
  editing: boolean;
  sourceId?: string;
  setUploadInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  deleteFile: () => void;
}

export default function ExistingContent({
  form,
  setOtherTags,
  editing,
  sourceId,
  setUploadInProgress,
  deleteFile,
}: ExistingContentProps) {
  const [availabilityLabels, setAvailabilityLabels] = useState<string[]>([
    form.getValues("availability"),
  ]);
  const [contentTypes, setContentTypes] = useState<string[]>([form.getValues("contentType")]);
  const [error] = useState(false);

  useEffect(() => {
    getAvailabilityLabels().then((response) => {
      setAvailabilityLabels(response);
    });

    getTagList().then((result) => {
      setContentTypes(
        result.ContentType.filter((contentType) => contentType.Text !== "Collection").map(
          (contentType) => contentType.Text,
        ),
      );
    });
  }, []);

  return (
    <Stack spacing={1}>
      <Controller
        control={form.control}
        name='contentType'
        render={({ field: { value }, fieldState: { error } }) => (
          <FormControl error={!!error} variant='standard' key='page1'>
            <InputLabel id='content-type-label'>Content Type</InputLabel>
            <Select
              labelId='content-type-label'
              id='content-type-select'
              value={value}
              label='Content Type'
              {...form.register("contentType", { required: true })}
            >
              {contentTypes.map((contentType) => (
                <MenuItem key={contentType} value={contentType}>
                  {contentType}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText id='contentType'>
              {error ? "Please select Content Type" : " "}
            </FormHelperText>
          </FormControl>
        )}
      />
      <LinkInput
        form={form}
        setValue={setOtherTags}
        editing={editing}
        sourceId={sourceId || ""}
        setUploadInProgress={setUploadInProgress}
        deleteFile={deleteFile}
      />
      <FormControl error={!!error} variant='standard'>
        <InputLabel id='availability'>Where can I find this?</InputLabel>
        <Select
          labelId='availability'
          id='availability'
          defaultValue={form.getValues("availability")}
          label='Where can I find this?'
          {...form.register("availability", { required: "Please select availability" })}
        >
          {availabilityLabels.map((label) => (
            <MenuItem key={label} value={label}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        id={"freeCheckbox"}
        defaultChecked={form.getValues("free")}
        control={<Checkbox name='free' defaultChecked={form.getValues("free")} />}
        label='Free'
        {...form.register("free")}
      />
    </Stack>
  );
}
