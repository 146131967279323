import { ReactNode, createContext, useEffect, useState } from "react";
import AuthContextValue from "./models/AuthContextValue";
import { getJWT } from "./helpers/helpers";

export const AuthContext = createContext<AuthContextValue>({
  isLoading: true,
  isAuthorized: false,
});

export default function AuthContextProvider({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [googleAuth, setGoogleAuth] = useState<gapi.auth2.GoogleAuth>();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [googleAccessToken, setGoogleAccessToken] = useState<string>();

  useEffect(() => {
    const scopes = [
      "https://www.googleapis.com/auth/documents",
      "https://www.googleapis.com/auth/drive",
      "https://www.googleapis.com/auth/spreadsheets",
      "https://www.googleapis.com/auth/gmail.send",
    ].join(" ");

    const setSigninStatus = async (googleAuthInstance: gapi.auth2.GoogleAuth) => {
      const user = googleAuthInstance.currentUser.get();
      const hasScopes = user.hasGrantedScopes(scopes);

      if (hasScopes) {
        const authResponse = user.getAuthResponse();
        const idToken = authResponse.id_token;
        const loginSucceded = await getJWT(idToken);
        setIsAuthorized(loginSucceded);
        setGoogleAccessToken(authResponse.access_token);
      } else {
        setIsAuthorized(false);
      }
    };

    gapi.load("client:auth2", async () => {
      // In practice, your app can retrieve one or more discovery documents.
      const docsDiscoveryUrl = "https://docs.googleapis.com/$discovery/rest?version=v1";
      const driveDiscoveryUrl = "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
      const slidesDiscoveryUrl = "https://slides.googleapis.com/$discovery/rest?version=v1";
      const sheetsDiscoveryUrl = "https://sheets.googleapis.com/$discovery/rest?version=v4";
      const contentSheetsDiscoveryUrl =
        "https://content-sheets.googleapis.com/$discovery/rest?version=v4";

      // Initialize the gapi.client object, which app uses to make API requests.
      // Get API key and client ID from API Console.
      // 'scope' field specifies space-delimited list of access scopes.
      await gapi.client.init({
        apiKey: "AIzaSyBI0jG_zfiz31Ct5Tyt48sCTOwGykWpxbY",
        clientId: "443420267186-4loku40pnc4c89r04qnj5e4aa6aurkt9.apps.googleusercontent.com",
        discoveryDocs: [
          docsDiscoveryUrl,
          driveDiscoveryUrl,
          slidesDiscoveryUrl,
          sheetsDiscoveryUrl,
          contentSheetsDiscoveryUrl,
        ],
        scope: scopes,
      });

      const googleAuthInstance = gapi.auth2.getAuthInstance();
      setGoogleAuth(googleAuthInstance);

      // Listen for sign-in state changes.
      googleAuthInstance.isSignedIn.listen(() => setSigninStatus(googleAuthInstance));

      await setSigninStatus(googleAuthInstance);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ googleAuth, isLoading, isAuthorized, googleAccessToken }}>
      {children}
    </AuthContext.Provider>
  );
}
