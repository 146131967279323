import { ReactNode, useContext } from "react";
import { AuthContext } from "../AuthContext";
import AuthContextValue from "../models/AuthContextValue";
import { Navigate, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";

export default function AuthProtection({ children }: { children: ReactNode }) {
  const { isAuthorized } = useContext<AuthContextValue>(AuthContext);
  const location = useLocation();

  if (!isAuthorized) {
    return <Navigate to='/login' replace={true} state={{ prevUrl: location.pathname }} />;
  }

  if (children) {
    return <>{children}</>;
  }

  return <Outlet />;
}
