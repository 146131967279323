import GoogleClientBase from "./GoogleClientBase";
import Slides from "./Slides";
import Document from "./Document";
import Spreadsheet from "./Spreadsheet";
import Website from "./Website";
import ContentType from "../../models/ContentType";

export default function getGoogleClient(contentType: ContentType): GoogleClientBase {
  switch(contentType.toLocaleLowerCase()) {
    case "presentation":
      return Slides;
    case "document":
      return Document;
    case "spreadsheet":
      return Spreadsheet;
    case "website":
      return Website;
    default:
      throw new Error(`Unknown content type "${contentType}"`);
  }
};
