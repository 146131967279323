import {
  Typography,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Stack,
  Button,
  TextField,
  Menu,
  MenuItem,
  Divider,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getParams } from "../../helpers/discover";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { getCollaboratorsList, useQuery } from "../../helpers/helpers";
import {
  getProjectDetails,
  createProject,
  updateProject,
  addProjectMember,
  removeProjectMember,
  addProjectContent,
  removeProjectContent,
  deleteProject,
} from "../../helpers/projects";
import { useContext, useEffect, useState } from "react";
import ProjectDetails from "../../models/ProjectDetails";
import ContentItem from "../../models/ContentItem";
import ItemsListView from "../details/ItemsListView";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import ProjectStatusChip from "./ProjectStatusChip";
import Collaborator from "../../models/Collaborator";
import EditCollaborators from "../shared/EditCollaborators";
import CheckIcon from "@mui/icons-material/Check";
import AlertInfo from "../../models/AlertInfo";
import differenceBy from "lodash/differenceBy";
import { getJWTDecoded } from "../../helpers/helpers";
import AdultOnly from "../shared/AdultOnly";
import Collection from "../addContent/Collection";
import { AlertSnack } from "../util/Alerts";
import { DeleteConfirmation } from "../util/DeleteConfirmation";
import AuthContextValue from "../../models/AuthContextValue";
import { AuthContext } from "../../AuthContext";
import { useAppContext } from "../util/AppContextHook";

const defaultProjectInformation: ProjectDetails = {
  id: -1,
  title: "New Project",
  description: "Description",
  status: "WIP",
  projectContent: [],
  members: [],
};

export default function Project() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const { googleAccessToken } = useContext<AuthContextValue>(AuthContext);
  const {
    list,
    setList,
    setCreatingList,
    setProjectUpdatesExist,
    editingOutputs,
    setEditingOutputs,
  } = useAppContext();

  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [addingResources, setAddingResources] = useState<boolean>(false);
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [project, setProject] = useState<ProjectDetails>(
    structuredClone(defaultProjectInformation),
  );
  const [availableCollaborators, setAvailableCollaborators] = useState<Collaborator[]>([]);
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [outputsAnchorEl, setOutputsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState<HTMLElement | null>(null);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<HTMLElement | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  const handleMenuClick = (event: any) => {
    setOptionsAnchorEl(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setOptionsAnchorEl(null);
  };
  const [alert, setAlert] = useState<AlertInfo | undefined>(undefined);

  const isNew = location.pathname.includes("project/new");

  const navigateToDiscover = () => {
    setList([]);
    setCreatingList(false);
    setEditingOutputs(false);
    navigate({
      pathname: `/`,
      search: `?${createSearchParams({
        ...getParams(query),
      })}`,
    });
  };

  const navigateToDetail = (projectId: number) => {
    navigate({
      pathname: `/project/${projectId}`,
      search: `?${createSearchParams({
        ...getParams(query),
      })}`,
    });
  };

  const updateProjectMetadata = async (project: ProjectDetails): Promise<ProjectDetails> => {
    if (project.id === -1) {
      const projectId = await createProject(project.title, project.description, project.status);
      setProjectUpdatesExist(true);
      navigateToDetail(projectId);
      return {
        ...project,
        id: projectId,
      };
    } else {
      await updateProject(project);
      setProjectUpdatesExist(true);
      return project;
    }
  };

  const updateProjectMembers = async (
    project: ProjectDetails,
    newMembers: Collaborator[],
  ): Promise<ProjectDetails> => {
    let updatedProject = project;

    if (project.id === -1) {
      const projectId = await createProject(project.title, project.description, project.status);
      updatedProject = {
        ...project,
        id: projectId,
      };
    }
    const removedMembers = differenceBy(
      project.members,
      newMembers,
      (member: Collaborator) => member.userId,
    );
    for (const member of removedMembers) {
      await removeProjectMember(updatedProject.id, member.userId, googleAccessToken);
    }

    const addedMembers = differenceBy(
      newMembers,
      project.members,
      (member: Collaborator) => member.userId,
    );
    for (const member of addedMembers) {
      await addProjectMember(updatedProject.id, member.userId, googleAccessToken);
    }

    if (project.id === -1) {
      navigateToDetail(updatedProject.id);
    }

    setProjectUpdatesExist(true);

    return {
      ...updatedProject,
      members: newMembers,
    };
  };

  const updateProjectContent = async (
    project: ProjectDetails,
    newContent: ContentItem[],
  ): Promise<ProjectDetails> => {
    let updatedProject = project;

    if (project.id === -1) {
      const projectId = await createProject(project.title, project.description, project.status);
      updatedProject = {
        ...project,
        id: projectId,
      };
    }

    const removedContent = differenceBy(
      project.projectContent,
      newContent,
      (content: ContentItem) => content.id,
    );
    for (const content of removedContent) {
      await removeProjectContent(updatedProject.id, content.id, content.projectContentType);
    }

    const addedContent = differenceBy(
      newContent,
      project.projectContent,
      (content: ContentItem) => content.id,
    );
    for (const content of addedContent) {
      await addProjectContent(
        updatedProject.id,
        content.id,
        content.projectContentType,
        googleAccessToken,
      );
    }

    if (project.id === -1) {
      navigateToDetail(updatedProject.id);
    }

    setProjectUpdatesExist(true);

    return {
      ...updatedProject,
      projectContent: newContent,
    };
  };

  const resetList = () => {
    stopAddingResources();
    stopEditingOutputs();
  };

  const handleAddResources = async () => {
    stopEditingOutputs();
    if (addingResources) {
      if (project.id === -1) {
        project.id = await createProject(project.title, project.description, project.status);
      }
      const outputs = project.projectContent.filter((c) => c.projectContentType === "OUT");
      const newResources = list.map((c) => ({ ...c, projectContentType: "RES" }));
      const newList = outputs.concat(newResources);
      const updatedProject = await updateProjectContent(project, newList);
      setProject(updatedProject);
    }

    const toggled = !addingResources;
    setAddingResources(toggled);
    setCreatingList(toggled);
    setList(project.projectContent.filter((c) => c.projectContentType === "RES"));
  };

  const handleEditOutputs = async () => {
    stopAddingResources();
    if (editingOutputs) {
      if (project.id === -1) {
        project.id = await createProject(project.title, project.description, project.status);
      }
      const resources = project.projectContent.filter((c) => c.projectContentType === "RES");
      const newOutputs = list.map((c) => ({ ...c, projectContentType: "OUT" }));
      const newList = resources.concat(newOutputs);
      const updatedProject = await updateProjectContent(project, newList);
      setProject(updatedProject);
    }

    const toggled = !editingOutputs;
    setEditingOutputs(toggled);
    setCreatingList(false);
    setList(project.projectContent.filter((c) => c.projectContentType === "OUT"));
  };

  const stopAddingResources = () => {
    if (addingResources) {
      setAddingResources(false);
      setList([]);
    }
  };

  const stopEditingOutputs = () => {
    if (editingOutputs) {
      setEditingOutputs(false);
      setList([]);
    }
  };

  const onCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  const onDeleteProject = async () => {
    await deleteProject(project.id);
    navigateToDiscover();
    setProjectUpdatesExist(true);
  };

  const onAddResourcesPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (addingResources) {
      handleAddResources();
    } else {
      setResourcesAnchorEl(event.currentTarget);
    }
  };

  const onAddResourcesPopoverClose = () => {
    setResourcesAnchorEl(null);
  };

  const onAddOutputsPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (editingOutputs) {
      handleEditOutputs();
    } else {
      setOutputsAnchorEl(event.currentTarget);
    }
  };

  const onAddOutputsPopoverClose = () => {
    setOutputsAnchorEl(null);
  };

  const onStatusPopoverClick = (event: React.MouseEvent<HTMLElement>) => {
    if (statusAnchorEl) {
      onStatusPopoverClose();
    } else {
      setStatusAnchorEl(event.currentTarget);
    }
  };

  const onStatusPopoverClose = () => {
    setStatusAnchorEl(null);
  };

  const handleAddContentClick = (type: "RES" | "OUT") => {
    resetList();
    navigate(
      {
        pathname: "/add/content",
        search: `?${createSearchParams({
          ...getParams(query),
        })}`,
      },
      { state: { projectId: project.id, type } },
    );
  };

  const handleCreateContentClick = (type: "RES" | "OUT") => {
    resetList();
    navigate(
      {
        pathname: "/add/new",
        search: `?${createSearchParams({
          ...getParams(query),
        })}`,
      },
      { state: { projectId: project.id, type } },
    );
  };

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProject({
      ...project,
      title: event.target.value,
    });
  };

  const saveTitleChange = async () => {
    const updatedProject = await updateProjectMetadata(project);
    setProject(updatedProject);
    setEditTitle(false);
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProject({
      ...project,
      description: event.target.value,
    });
  };

  const saveDescriptionChange = async () => {
    const updatedProject = await updateProjectMetadata(project);
    setProject(updatedProject);
    setEditDescription(false);
  };

  const onStatusChange = async (newStatus: string) => {
    let updatedProject = {
      ...project,
      status: newStatus,
    };

    updatedProject = await updateProjectMetadata(updatedProject);

    setProject(updatedProject);
  };

  useEffect(() => {
    if (isNew) {
      setProject(structuredClone(defaultProjectInformation));
      document.title = "New Project";
    } else {
      resetList();
      const projectId = location.pathname.split("/")[2];

      getProjectDetails(projectId).then((projectDetails: ProjectDetails) => {
        setProject(projectDetails);
        document.title = projectDetails.title;
      });
    }
  }, [location.pathname, isNew]);

  useEffect(() => {
    getCollaboratorsList().then((response) => {
      setAvailableCollaborators(response);
    });
  }, []);

  const onTeamMembersChange = async (members: Collaborator[]) => {
    const updatedProject = await updateProjectMembers(project, members);

    setProject(updatedProject);
  };

  const isAdult: boolean = getJWTDecoded().isAdult;

  return (
    <Box>
      <AppBar position={"sticky"} sx={{ backgroundColor: "#ba0a45" }}>
        <Toolbar>
          <Stack
            spacing={1}
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          >
            <IconButton
              onClick={navigateToDiscover}
              size='small'
              edge='start'
              color='inherit'
              sx={{ margin: "auto 0 !important" }}
            >
              <CloseIcon />
            </IconButton>
            <Stack spacing={1} alignItems={"center"} direction={"row"}>
              {editTitle ? (
                <Stack spacing={1} alignItems={"center"} direction={"row"}>
                  <TextField
                    value={project.title}
                    size='small'
                    variant='filled'
                    autoFocus
                    hiddenLabel
                    onChange={onTitleChange}
                    onBlur={saveTitleChange}
                    sx={{
                      input: { color: "#fff" },
                      div: { color: "#fff" },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton color='inherit' onClick={() => {}}>
                            <CheckIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              ) : (
                <Button color='inherit' onClick={isAdult ? () => setEditTitle(true) : () => {}}>
                  <Stack spacing={1} alignItems={"center"} direction={"row"}>
                    <Typography variant={"h6"} component={"h6"}>
                      {project.title}
                    </Typography>
                    <AdultOnly>
                      <EditIcon
                        className='edit-icon'
                        color='inherit'
                        sx={{ height: 20, width: 20 }}
                      />
                    </AdultOnly>
                  </Stack>
                </Button>
              )}
            </Stack>
            {isAdult && (
              <div style={{ margin: "auto 0" }}>
                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                  <Tooltip title='Options'>
                    <IconButton
                      onClick={handleMenuClick}
                      aria-controls={optionsAnchorEl ? "account-menu" : undefined}
                      aria-haspopup='true'
                      aria-expanded={optionsAnchorEl ? "true" : undefined}
                      sx={{ color: "white", padding: "0 0 0 0", marginLeft: "8px" }}
                    >
                      <MoreVertIcon fontSize='medium' sx={{ color: "white" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={optionsAnchorEl}
                  id='account-menu'
                  open={!!optionsAnchorEl}
                  onClose={handleOptionsClose}
                  onClick={handleOptionsClose}
                  disableScrollLock={true}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {/* <MenuItem onClick={() => onDeleteProject()}> */}
                  <MenuItem onClick={() => setShowDeleteConfirmation(true)}>
                    Delete Project
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => onCopyLink()}>Copy Share Link</MenuItem>
                </Menu>
              </div>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      {showDeleteConfirmation && (
        <DeleteConfirmation
          cancel={() => setShowDeleteConfirmation(false)}
          confirm={() => onDeleteProject()}
        />
      )}
      <div className={"body flex-grow text-left flex p-4 flex-col w-full"}>
        <Stack spacing={3}>
          <Box>
            <IconButton onClick={onStatusPopoverClick} sx={{ borderRadius: "16px" }}>
              <ProjectStatusChip chevron={true} status={project.status}></ProjectStatusChip>
            </IconButton>
          </Box>
          <Menu
            anchorEl={statusAnchorEl}
            id='change-status'
            open={isAdult && !!statusAnchorEl}
            onClose={onStatusPopoverClose}
            onClick={onStatusPopoverClose}
            disableScrollLock={true}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem dense divider onClick={() => onStatusChange("WIP")}>
              Preparing
            </MenuItem>
            {/*<Divider />*/}
            <MenuItem dense divider onClick={() => onStatusChange("ACT")}>
              In Progress
            </MenuItem>
            {/*<Divider />*/}
            <MenuItem dense onClick={() => onStatusChange("COM")}>
              Complete
            </MenuItem>
          </Menu>

          <Stack alignItems='start'>
            {editDescription ? (
              <TextField
                value={project.description}
                size='small'
                variant='filled'
                autoFocus
                hiddenLabel
                multiline
                fullWidth
                onChange={onDescriptionChange}
                onBlur={saveDescriptionChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton color='inherit' onClick={() => {}}>
                        <CheckIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Button
                color='inherit'
                onClick={isAdult ? () => setEditDescription(true) : () => {}}
                sx={[
                  { textTransform: "none", ".edit-icon": { visibility: "hidden" } },
                  { "&:hover": { borderColor: "e5e7eb", ".edit-icon": { visibility: "visible" } } },
                ]}
              >
                <Stack spacing={1} alignItems={"center"} direction={"row"}>
                  <Typography variant={"subtitle1"} component={"h6"}>
                    {project.description}
                  </Typography>
                  <AdultOnly>
                    <EditIcon
                      className='edit-icon'
                      color='inherit'
                      sx={{ height: 20, width: 20 }}
                    />
                  </AdultOnly>
                </Stack>
              </Button>
            )}
          </Stack>
          <Box>
            <Typography variant='subtitle1' component='div' align='left' noWrap>
              Resources
            </Typography>
            {addingResources ? (
              <Collection />
            ) : (
              <ItemsListView
                items={project.projectContent.filter((c) => c.projectContentType === "RES")}
              />
            )}

            <Box sx={{ paddingTop: "10px", display: "flex", justifyContent: "flex-end" }}>
              <Button
                aria-describedby='add-resources'
                size='small'
                onClick={onAddResourcesPopoverClick}
                sx={{
                  border: "1px solid",
                  borderColor: "primary.main",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  paddingLeft: "10px",
                  paddingRight: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {addingResources
                  ? null
                  : project.projectContent.find((i) => i.projectContentType === "RES")
                    ? "Manage Resources"
                    : "Add Resources"}
                {addingResources ? (
                  <DoneIcon sx={{ marginLeft: "0px" }} />
                ) : (
                  <EditIcon sx={{ marginLeft: "4px" }} />
                )}
              </Button>
              <Menu
                anchorEl={resourcesAnchorEl}
                id='add-resources'
                open={!!resourcesAnchorEl}
                onClose={onAddResourcesPopoverClose}
                onClick={onAddResourcesPopoverClose}
                disableScrollLock={true}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleAddResources}>
                  {project.projectContent.find((i) => i.projectContentType === "RES")
                    ? "Edit Resource List"
                    : "Add Resources"}
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleAddContentClick("RES")}>Add Link or File</MenuItem>
                <Divider />
                <MenuItem onClick={() => handleCreateContentClick("RES")}>
                  Create Something New
                </MenuItem>
              </Menu>
            </Box>

            <Typography variant='subtitle1' component='div' align='left' noWrap>
              Project Outputs
            </Typography>
            {editingOutputs ? (
              <Collection />
            ) : (
              <ItemsListView
                items={project.projectContent.filter((c) => c.projectContentType === "OUT")}
              />
            )}
            <Box sx={{ paddingTop: "10px", display: "flex", justifyContent: "flex-end" }}>
              <Button
                aria-describedby='add-outputs'
                size='small'
                onClick={onAddOutputsPopoverClick}
                sx={{
                  border: "1px solid",
                  borderColor: "primary.main",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  paddingLeft: "10px",
                  paddingRight: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {editingOutputs
                  ? null
                  : project.projectContent.find((i) => i.projectContentType === "OUT")
                    ? "Manage Outputs"
                    : "Add Outputs"}
                {editingOutputs ? (
                  <DoneIcon sx={{ marginLeft: "0px" }} />
                ) : (
                  <EditIcon sx={{ marginLeft: "4px" }} />
                )}
              </Button>
              <Menu
                anchorEl={outputsAnchorEl}
                id='add-resources'
                open={!!outputsAnchorEl}
                onClose={onAddOutputsPopoverClose}
                onClick={onAddOutputsPopoverClose}
                disableScrollLock={true}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {project.projectContent.find((i) => i.projectContentType === "OUT") && (
                  <MenuItem onClick={handleEditOutputs}>Edit Output List</MenuItem>
                )}
                <Divider />
                <MenuItem onClick={() => handleAddContentClick("OUT")}>Add Link or File</MenuItem>
                <Divider />
                <MenuItem onClick={() => handleCreateContentClick("OUT")}>
                  Create Something New
                </MenuItem>
              </Menu>
            </Box>

            <Typography variant='subtitle1' component='div' align='left' noWrap>
              Team Members
            </Typography>
            <EditCollaborators
              availableTags={availableCollaborators}
              selectedTags={project.members}
              setSelectedTags={onTeamMembersChange}
            />
          </Box>
        </Stack>
      </div>
      {alert && <AlertSnack alertInfo={alert} setAlert={setAlert} />}
    </Box>
  );
}
