import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Login from "./components/Login";
import AuthProtection from "./components/AuthProtection";
import Discover from "./components/discover/Discover";
import Details from "./components/details/Details";
import AddContent from "./components/addContent/AddContent";
import CreateContent from "./components/addContent/CreateContent";
import Reminder from "./components/reminders/Reminder";
import Project from "./components/projects/Project";
import AppContextProvider from "./components/AppContext";
import NotAvailable from "./components/NotAvailable";

function AppRouter() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/'>
        <Route path='/login' element={<Login />} />
        <Route
          path='/'
          element={
            <AuthProtection>
              <AppContextProvider>
                <Discover />
              </AppContextProvider>
            </AuthProtection>
          }
        >
          <Route path='/project/:id' element={<Project />} />
          <Route path='/details/:id' element={<Details />} />
          <Route path='/details/:id/edit' element={<AddContent editing={true} />} />
          <Route path='/details/:id/clone' element={<AddContent cloning={true} />} />
          <Route path='/add'>
            <Route path='content' element={<AddContent />} />
            <Route path='new' element={<CreateContent />} />
            <Route path='collection' element={<AddContent />} />
            <Route path='reminder' element={<Reminder />} />
          </Route>
          <Route path='*' element={<Navigate to='/' replace />} />
          <Route path='/not-available' element={<NotAvailable />} />
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default AppRouter;
