import axios, { AxiosResponse } from 'axios';
import ContentItem from '../models/ContentItem';
import TagArrays from '../models/TagArrays';
import jwt_decode from 'jwt-decode';
import DecodedJWT from '../models/DecodedJWT';
import Collaborator from '../models/Collaborator';
import ContentDetails from '../models/ContentDetails';
import ContentDetailsCreate from '../models/ContentDetailsCreate';
import ContentDetailsUpdate from '../models/ContentDetailsUpdate';
import PersonalContentItem from '../models/PersonalContentItem';
import { AddFormValues } from '../components/addContent/AddContent';
import ContentSearchResults from '../models/ContentSearchResults';
import { useMemo } from 'react';
import { useLocation } from "react-router-dom";
import Tag from '../models/Tag';

const generalMiscContentTypeId = 22;

const axiosInstance = axios.create({
  baseURL: 'https://api.pbeej.com',
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'

const getTagList = async (): Promise<TagArrays> => {
  try {
    const tagArrays: TagArrays = { ContentType: [], Keyword: [], AgeRange: [] };
    const response = await axiosInstance.get(`/content/filters`);

    tagArrays.ContentType = response.data.contentTypes.map((t: any) => ({ TagType: 'ContentType', Text: t.tagText, Id: t.id }));
    tagArrays.AgeRange = response.data.ages.map((t: any) => ({ TagType: 'AgeRange', Text: t.tagText, Id: t.id }));
    tagArrays.Keyword = response.data.keywords.map((t: any) => ({ TagType: 'Keyword', Text: t.tagText, Id: t.id }));

    return tagArrays;
  } catch (exception) {
    throw new Error('get tag list failed');
  }
};

const getJWT = async (idToken: string): Promise<boolean> => {
  try {
    const response = await axiosInstance.post(`/auth/token`, { idToken });
    axiosInstance.defaults.headers.common['Authorization'] = response.data as string;
    return true;
  } catch (exception) {
    return false;
  }
}

const dropJWT = (): void => { axiosInstance.defaults.headers.common['Authorization'] = '' }

const getJWTDecoded = (): DecodedJWT => {
  return jwt_decode(axiosInstance.defaults.headers.common['Authorization'].toString())
}

const searchContent = async (param: any): Promise<ContentSearchResults> => {
  try {
    const response = await axiosInstance.post(`/content/search`, param);
    const result = response.data as ContentSearchResults;
    result.searchResults.sort((a: ContentItem, b: ContentItem) => {
      if (a.matchCount > b.matchCount) {
        return -1
      } else if (a.matchCount < b.matchCount) {
        return 1
      } else {
        return 0
      }
    });
    return result;
  } catch (exception) {
    throw new Error(`ERROR received from /content/search: ${exception}\n`);
  }
}

const getContentAll = async (id: number): Promise<ContentDetails> => {
  try {
    const response = await axiosInstance.get(`/content?id=${id}`) as AxiosResponse<ContentDetails>;
    // TODO determine intended usage of NA age tag
    response.data.ageTags = response.data.ageTags.filter((tag) => tag.tagText !== "NA");
    
    return response.data;
  } catch (exception) {
    if(exception.toString().includes('404')) {
        // navigate to not-available page
        window.location.href = `${window.location.origin}/not-available`; 
      }
    throw new Error(`ERROR received from /content?id=${id}\n`);
  }
}

const getContentStatusLabels = async () => {
  try {
    const response = await axiosInstance.get(`/content/status-labels`);
    return response.data;
  } catch (ex) {
    throw new Error('ERROR with status endpoint')
  }
}

const getPersonalWork = async (wip: boolean = true, shared: boolean = false, completedPrivately: boolean = false):Promise<PersonalContentItem[]> => {
  try {
    const response = await axiosInstance.get(`/content/private?workInProgress=${wip}&shared=${shared}&completedPrivately=${completedPrivately}`);
    return response.data;
  } catch (ex) {
    throw new Error(`ERROR received from /content/private \n. ${ex}`);
  }
}

const createContentPlaceholder = async (typeId: number, title: string, link: string, collaborators: number[], sourceId: string): Promise<number> => {
  try {
    const response = await axiosInstance.post('/content/placeholder', {
      typeId,
      title,
      link,
      collaborators,
      sourceType: 'google',
      sourceId
    });

    return (response.data as { contentId: number }).contentId;
  } catch (exception) {
    throw new Error('ERROR received from POST /content/placeholder');
  }
}

const getAvailabilityLabels = async () => {
  try {
    const response = await axiosInstance.get('/content/availability-labels');
    const labels: string[] = response.data;

    return labels;
  } catch (exception) {
    throw new Error('ERROR received from GET /content/availability-labels');
  }
}

const createNewContent = async (newContent: AddFormValues, contentTypes: Tag[]) => {
  try {
    const contentDetails: ContentDetailsCreate = {
      typeId: contentTypes.find(contentType => contentType.Text === newContent.contentType)?.Id ?? generalMiscContentTypeId,
      title: newContent.title,
      description: newContent.description,
      link: newContent.link,
      author: newContent.author,
      availability: newContent.availability,
      free: newContent.free,
      statusCode: newContent.statusCode,
      vetted: false,
      parentGuidanceRequired: newContent.parentGuidanceRequired,
      parentGuidanceReason: newContent.parentGuidanceReason,
      sourceId: newContent.sourceId || '',
      sourceType: newContent.sourceType || '',
      keywordTagIds: Array.from(newContent.selectedKeywords).map(k => k.Id),
      ageTagIds: Array.from(newContent.selectedAgeRanges),
      newKeywords: newContent.newKeywords || [],
      collaborators: newContent.collaborators ? newContent.collaborators.map((c: Collaborator) => c.userId) : [],
    }
    const response = await axiosInstance.post('/content', contentDetails);
    return (response.data as { contentId: number }).contentId;
  } catch (exception) {
    throw new Error('ERROR received from POST /content');
  }
}

const createCollection = async (newCollection: AddFormValues) => {
  try {
    const contentDetails: ContentDetailsCreate = {
      typeId: 34,
      title: newCollection.title,
      description: newCollection.description,
      // link should be null for collections
      author: newCollection.author,
      availability: newCollection.availability,
      free: newCollection.free,
      statusCode: newCollection.statusCode,
      vetted: false,
      collectionContentIds: newCollection.collectionItems,
      parentGuidanceRequired: newCollection.parentGuidanceRequired,
      parentGuidanceReason: newCollection.parentGuidanceReason,
      sourceId: null,
      sourceType: null,
      keywordTagIds: Array.from(newCollection.selectedKeywords).map(k => k.Id),
      ageTagIds: Array.from(newCollection.selectedAgeRanges),
      newKeywords: newCollection.newKeywords || [],
      collaborators: newCollection.collaborators ? newCollection.collaborators.map((c: Collaborator) => c.userId) : [],
    }
    const response = await axiosInstance.post('/content', contentDetails);
    return (response.data as { contentId: number }).contentId;
  } catch (exception) {
    console.error(exception)
    throw new Error('ERROR received from POST /content/collection');
  }
}

const saveDetails = async (details: AddFormValues, contentTypes: Tag[], contentId: number) => {
  try {
    const contentDetails: ContentDetailsUpdate = {
      id: contentId,
      typeId: contentTypes.find(contentType => contentType.Text === details.contentType)?.Id ?? generalMiscContentTypeId,
      title: details.title,
      description: details.description,
      link: details.link,
      author: details.author,
      availability: details.availability,
      statusCode: details.statusCode,
      free: details.free,
      vetted: false,
      sourceId: details.sourceId,
      sourceType: details.sourceType,
      parentGuidanceRequired: details.parentGuidanceRequired,
      parentGuidanceReason: details.parentGuidanceReason,
      keywordTagIds: Array.from(details.selectedKeywords).map(k => k.Id),
      ageTagIds: Array.from(details.selectedAgeRanges),
      newKeywords: details.newKeywords || [],
      collaborators: details.collaborators ? details.collaborators.map((c: Collaborator) => c.userId) : [],
      collectionContentIds: details.collectionItems,
    }

    

    const response = await axiosInstance.put('/content', contentDetails);
    return response.status;

  } catch (exception) {
    throw new Error('ERROR received from PUT /content');
  }
}

const getCollaboratorsList = async () => {
  try {
    const response = await axiosInstance.get('/collaborator/all');

    return response.data as Collaborator[];
  } catch (exception) {
    throw new Error('ERROR received from GET /collaborator/all');
  }
}

const getCollaboratorsEmails = async (collaboratorsIds: number[]): Promise<string[]> => {
  try {
    const response = await axiosInstance.post('/collaborator/emails', collaboratorsIds);

    return response.data as string[];
  } catch (exception) {
    throw new Error('ERROR received from POST /collaborator/emails');
  }
}

const deleteContent = async (contentId: number) => {
  try {
    await axiosInstance.delete(`/content?id=${contentId}`)
  } catch (ex) {
    throw new Error('ERROR received from DELETE /content/:id')
  }
}

const deleteSharedDriveFile = async (fileId: string) => {
  await axiosInstance.delete(`/content/uploadedFile?fileId=${fileId}`);
}

const getBannerMessage = async (messageType: string): Promise<string> => {
  try {
    const response = await axiosInstance.get(`/admin/message/${messageType}`)
    return response.data.message;
  } catch (exception) {
    throw new Error(`ERROR received from GET /admin/message/${messageType}`)
  }
}

const markMessageAcknowledged = async () => {
  try {
    await axiosInstance.post('/admin/alert/markRead')
  } catch (ex) {
    throw new Error(`ERROR received from POST /admin/alert/markRead: ${ex}`)
  }
}

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

const useQuery = () => {
  const location = useLocation();
  const search = location.search;

  return useMemo(() => new URLSearchParams(search), [search]);
}

export {
  getTagList,
  getJWT,
  getJWTDecoded,
  searchContent,
  dropJWT,
  getContentAll,
  createContentPlaceholder,
  getPersonalWork,
  getAvailabilityLabels,
  createNewContent,
  createCollection,
  getContentStatusLabels,
  saveDetails,
  getCollaboratorsList,
  getCollaboratorsEmails,
  getBannerMessage,
  deleteContent,
  deleteSharedDriveFile,
  markMessageAcknowledged,
  groupBy,
  useQuery,
  axiosInstance,
}
