import { Link } from "react-router-dom";

export default function NotAvailable() {
  return (
    <div className='flex items-center justify-center h-screen bg-gray-100'>
      <div className='text-center'>
        <h2 className='text-2xl md:text-3xl font-semibold text-gray-700 mt-4'>Not Available</h2>
        <Link
          to='/'
          className='mt-6 inline-block bg-[#ba0a45] text-white py-3 px-6 rounded-lg hover:bg-[#ba0a45] transition duration-300'
        >
          Keep learning!
        </Link>
      </div>
    </div>
  );
}
