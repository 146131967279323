import { ReactNode, useContext } from "react";
import { AuthContext } from "../AuthContext";
import AuthContextValue from "../models/AuthContextValue";
import logo from "../assets/pbeej-logo.svg";
import { motion, AnimatePresence } from "framer-motion";

export default function SplashScreen({ children }: { children: ReactNode }) {
  const { isLoading } = useContext<AuthContextValue>(AuthContext);

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          key='splash'
          className={"h-screen w-screen flex items-center justify-center"}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.5 }}
        >
          <img src={logo} alt={"pbeej logo svg"} className={"w-[400px] lg:w-[500px] m-4"} />
        </motion.div>
      )}

      {!isLoading && (
        <motion.div
          key='main'
          className={"absolute h-[100dvh] w-screen flex justify-center items-center"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeIn", duration: 0.5, delay: 0.5 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
