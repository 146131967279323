import { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate, createSearchParams, useLocation, Navigate } from "react-router-dom";
import {
  Popover,
  Fab,
  Container,
  Stack,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import ContentType from "../../models/ContentType";
import CollaboratorModel from "../../models/Collaborator";
import Tag from "../../models/Tag";
import EditCollaborators from "../shared/EditCollaborators";
import {
  createContentPlaceholder,
  getCollaboratorsList,
  getTagList,
  getCollaboratorsEmails,
} from "../../helpers/helpers";
import { getParams } from "../../helpers/discover";
import {
  PresentationTooltip,
  DocumentTooltip,
  SpreadsheetTooltip,
  WebsiteTooltip,
} from "../util/ContentTypeTooltips";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ExternalLink from "../util/ExternalLink";
import GoogleClient from "../../services/Google/GoogleClient";
import getGoogleClient from "../../services/Google";
import openLinkInNewTab from "../../helpers/openLinkInNewTab";
import { CloseConfirmation } from "../util/CloseConfirmation";
import ReactRouterPrompt from "react-router-prompt";
import AlertInfo from "../../models/AlertInfo";
import { AlertType } from "../../models/enums/AlertType";
import { useMediaQuery } from "react-responsive";
import { addProjectContent, getProjectDetails } from "../../helpers/projects";
import AuthContextValue from "../../models/AuthContextValue";
import { AuthContext } from "../../AuthContext";

interface CollaboratorTag {
  userId: number;
  userName: string;
}

interface CreateContentFormValues {
  contentType: ContentType;
  title: string;
  collaborators: CollaboratorTag[];
}

export default function CreateContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const { googleAccessToken } = useContext<AuthContextValue>(AuthContext);
  const [formDefaultData] = useState<CreateContentFormValues>({
    title: "",
    collaborators: [],
    contentType: "presentation",
  });
  const [contentTypes, setContentTypes] = useState<Array<Tag>>([]);
  const [collaboratorList, setCollaboratorsList] = useState<CollaboratorModel[]>([]);
  const [collaborators, setCollaborators] = useState<CollaboratorTag[]>([]);
  const [contentType, setContentType] = useState<ContentType>("presentation");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMobile = useMediaQuery({ query: "(max-width: 985px)" });
  const [savedSuccessfully, setSavedSuccessfully] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertInfo>(null);
  const [creating, setCreating] = useState<boolean>(false);

  const form = useForm<CreateContentFormValues>({
    defaultValues: formDefaultData,
  });

  function useQuery() {
    let search = location.search;

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const navigateToDiscover = () => {
    navigate({
      pathname: "/",
      search: `?${createSearchParams({
        ...getParams(query),
      })}`,
    });
  };

  const infoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const createContent = async () => {
    setCreating(true);
    let alert: AlertInfo = {
      type: AlertType.Success,
      dismissable: true,
    };
    if (contentType === "website") {
      navigate({
        pathname: `/add/content`,
        search: `?${createSearchParams({ ...getParams(query) })}`,
      });
    } else {
      const googleClient = getGoogleClient(contentType);
      const collaboratorsIds = collaborators.map((collaborator) => collaborator.userId);

      try {
        const [collaboratorsEmails, fileId] = await Promise.all([
          getCollaboratorsEmails(collaboratorsIds),
          googleClient.create(form.getValues("title")),
        ]);

        let googleLink = googleClient.getLink(fileId);

        // Classic browser hackery. setTimeout callback run in the main thread instead of the async thread so Safari is ok with that
        // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari/70463940#70463940
        setTimeout(() => {
          openLinkInNewTab(googleLink, true);
        }, 0);

        GoogleClient.addCollaborators(fileId, collaboratorsEmails);
        const mappedType = contentTypes.find(
          (x) => x.Text.toLocaleLowerCase() === contentType.toLocaleLowerCase(),
        ) || { Id: 0 };
        let contentId = await createContentPlaceholder(
          mappedType.Id,
          form.getValues("title"),
          googleLink,
          collaboratorsIds,
          fileId,
        );
        if (location.state && location.state.projectId) {
          await addProjectContent(
            location.state.projectId,
            contentId,
            location.state.type,
            googleAccessToken,
          );
        }

        alert.message = "Your content has successfully been created.";
        setAlert(alert);
        setSavedSuccessfully(true);
        setCreating(false);
      } catch {
        alert.type = AlertType.Error;
        alert.message = "Sorry. Something went wrong. Please try that again.";
        setAlert(alert);
      }
    }
  };

  useEffect(() => {
    getTagList().then((response) => {
      setContentTypes(response.ContentType);
    });
    getCollaboratorsList().then((response) => {
      setCollaboratorsList(response);
    });

    if (location.state && location.state.projectId) {
      getProjectDetails(location.state.projectId).then((data) => {
        setCollaborators(data.members);
      });
    }
  }, []);

  const { projectId } = location.state || {};

  let pathname = "/";
  if (projectId) {
    pathname = `/project/${projectId}`;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <form
        onSubmit={form.handleSubmit(() => {
          createContent();
        })}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <ReactRouterPrompt when={form.formState.isDirty && !savedSuccessfully}>
          {({ isActive, onConfirm, onCancel }) =>
            isActive ? <CloseConfirmation keepEditing={onCancel} loseChanges={onConfirm} /> : null
          }
        </ReactRouterPrompt>
        {savedSuccessfully && <Navigate to={pathname} replace={true} state={{ alert }} />}

        <AppBar
          sx={{ backgroundColor: "white", color: "black", marginBottom: "20px" }}
          position='static'
        >
          <Toolbar>
            <IconButton
              onClick={() => navigateToDiscover()}
              size='small'
              edge='start'
              color='inherit'
              sx={{ mr: 2 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              Create Content
            </Typography>
            <Button
              onClick={() => navigateToDiscover()}
              color={"primary"}
              variant={"outlined"}
              size={"small"}
              sx={{ margin: "auto 0 !important", marginLeft: "0.5em !important" }}
            >
              Cancel
            </Button>
            <Button
              color={"success"}
              variant={"contained"}
              size={"small"}
              type={"submit"}
              disabled={creating}
              sx={{ margin: "auto 0 !important", marginLeft: "0.5em !important" }}
            >
              {contentType === "website" ? "Continue" : "Create"}
            </Button>
          </Toolbar>
        </AppBar>
        <Container
          sx={{
            height: "100%",
          }}
        >
          <form className='flex flex-col justify-around h-full'>
            <Stack spacing={2}>
              <Controller
                control={form.control}
                name='contentType'
                defaultValue='presentation'
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <ToggleButtonGroup
                    color='primary'
                    value={contentType}
                    exclusive
                    onChange={(event, newType) => {
                      if (newType) {
                        setContentType(newType);
                        onChange(event);
                      }
                    }}
                    aria-label='ContentType'
                    sx={{
                      margin: "auto !important",
                    }}
                    orientation={isMobile ? "vertical" : "horizontal"}
                  >
                    <ToggleButton value='presentation'>Presentation</ToggleButton>
                    <ToggleButton value='document'>Document</ToggleButton>
                    <ToggleButton value='spreadsheet'>Spreadsheet</ToggleButton>
                    <ToggleButton value='website'>Website</ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
              {contentType === "website" && (
                <p>
                  <ExternalLink link='https://sites.google.com/new?tgif=d' text='Click Here' /> to
                  go directly to Google Sites to create a new website.
                  <br></br>
                  <br></br>
                  Once you have published the Site, click Continue and copy its URL into the Link
                  field.
                </p>
              )}
              {contentType !== "website" && (
                <>
                  <Controller
                    control={form.control}
                    name='title'
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <>
                          <TextField
                            error={!!error}
                            {...form.register("title", { required: true })}
                            id='title'
                            label='Title'
                            variant='standard'
                            aria-describedby='title'
                          />
                          {error && (
                            <FormHelperText id='title'>
                              Please provide a unique title
                            </FormHelperText>
                          )}
                        </>
                      );
                    }}
                  />
                  <Controller
                    control={form.control}
                    name='collaborators'
                    defaultValue={[]}
                    render={({ field: { onChange } }) => (
                      <EditCollaborators
                        availableTags={collaboratorList}
                        selectedTags={collaborators}
                        setSelectedTags={(event) => {
                          setCollaborators(event || []);
                          onChange(event);
                        }}
                      />
                    )}
                  />
                </>
              )}
            </Stack>
          </form>
        </Container>
      </form>
      <Fab
        size='small'
        aria-label='info'
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          margin: "10px",
          backgroundColor: "info",
        }}
        onClick={infoClick}
      >
        <InfoOutlinedIcon />
      </Fab>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 1, fontSize: "14px" }}>
          {contentType === "presentation" && PresentationTooltip}
          {contentType === "document" && DocumentTooltip}
          {contentType === "spreadsheet" && SpreadsheetTooltip}
          {contentType === "website" && WebsiteTooltip}
        </Typography>
      </Popover>
    </Box>
  );
}
