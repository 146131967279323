import { ReactNode, createContext, useState } from "react";
import ContentItem from "../models/ContentItem";

interface AppContextInterface {
  creatingList: boolean;
  setCreatingList: React.Dispatch<React.SetStateAction<boolean>>;
  list: Array<ContentItem>;
  setList: React.Dispatch<React.SetStateAction<Array<ContentItem>>>;
  activeResource: number;
  setActiveResource: React.Dispatch<React.SetStateAction<number>>;
  activeResourceName: string;
  setActiveResourceName: React.Dispatch<React.SetStateAction<string>>;
  addingToProject: boolean;
  setAddingToProject: React.Dispatch<React.SetStateAction<boolean>>;
  editingOutputs: boolean;
  setEditingOutputs: React.Dispatch<React.SetStateAction<boolean>>;
  setProjectUpdatesExist: React.Dispatch<React.SetStateAction<boolean>>;
  projectUpdatesExist: boolean;
}

export const AppContext = createContext<AppContextInterface | undefined>(undefined);

export default function AppContextProvider({ children }: { children: ReactNode }) {
  const [list, setList] = useState<ContentItem[]>([]);
  const [creatingList, setCreatingList] = useState<boolean>(false);
  const [activeResource, setActiveResource] = useState<number>(null);
  const [activeResourceName, setActiveResourceName] = useState<string>(null);
  const [addingToProject, setAddingToProject] = useState<boolean>(false);
  const [newProjectExists, setNewProjectExists] = useState<boolean>(false);
  const [editingOutputs, setEditingOutputs] = useState<boolean>(false);

  return (
    <AppContext.Provider
      value={{
        list,
        setList,
        creatingList,
        setCreatingList,
        activeResource,
        setActiveResource,
        activeResourceName,
        setActiveResourceName,
        addingToProject,
        setAddingToProject,
        editingOutputs: editingOutputs,
        setEditingOutputs: setEditingOutputs,
        projectUpdatesExist: newProjectExists,
        setProjectUpdatesExist: setNewProjectExists,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
