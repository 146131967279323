import { Typography, IconButton, Stack, Card, CardActionArea } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import ContentItem from "../../models/ContentItem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import openLinkInNewTab from "../../helpers/openLinkInNewTab";
import { useEffect } from "react";
import { useIntersectionObserver } from "react-intersection-observer-hook";
import { StatusTag, MiniStatusTag } from "../tags/StatusTag";
import { useMediaQuery } from "react-responsive";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useAppContext } from "../util/AppContextHook";

interface ItemArgs {
  result: ContentItem;
  previewPanelId: number | undefined;
  renderedInList: boolean;
  onSelect: (item: ContentItem) => void;
  notifyIsVisible: () => void;
  editingList: boolean;
}

export default function Item({
  result,
  renderedInList,
  editingList,
  onSelect,
  notifyIsVisible,
}: ItemArgs) {
  const [ref, { entry }] = useIntersectionObserver();
  const isVisible = entry && entry.isIntersecting;
  const isMobile = useMediaQuery({ query: "(max-width: 985px)" });

  useEffect(() => {
    if (isVisible) {
      notifyIsVisible();
    }
  }, [isVisible]);

  const { list, setList } = useAppContext();

  function getIcon() {
    if (editingList && !renderedInList) {
      if (list.map((item) => item.id).includes(result.id)) {
        return <CheckCircleIcon color={"success"} />;
      }
      return <RadioButtonUncheckedIcon color={"success"} />;
    }
    return null;
  }

  function openLink(event: React.MouseEvent<HTMLElement>, link: string) {
    event.stopPropagation();

    openLinkInNewTab(link);
  }

  function itemClick(result: ContentItem) {
    if (editingList && !renderedInList) {
      const isInCollection = list.map((item) => item.id).includes(result.id);
      if (isInCollection) {
        setList(list.filter((item) => item.id !== result.id));
      } else {
        setList(list.concat(result));
      }
    } else {
      onSelect(result);
    }
  }

  return (
    <Card sx={{ maxHeight: 125, marginBottom: "5px" }} ref={ref}>
      <CardActionArea sx={{ padding: 1, display: "flex" }} onClick={(e) => itemClick(result)}>
        {editingList && <span className='m-auto mr-2'>{getIcon()}</span>}

        <Stack sx={{ overflow: "hidden" }}>
          <Typography variant='subtitle2' align='left' noWrap>
            {result.title}
          </Typography>
          <Typography variant='caption' color='text.secondary' align='left' noWrap>
            {result.author ? `by ${result.author}` : ""}
          </Typography>
        </Stack>

        <div className={"flex flex-row align-center justify-end grow"}>
          <Stack direction='row' spacing={1} alignItems='center'>
            {isMobile ? (
              <MiniStatusTag text={result.statusCode} />
            ) : (
              <StatusTag text={result.statusCode} />
            )}

            {!result.free && (
              <div className={"flex align-center justify-center"}>
                <PaidIcon fontSize='small' />
              </div>
            )}

            {result.link?.length > 0 && (
              <IconButton
                size={"small"}
                component={"span"}
                onClick={(event: React.MouseEvent<HTMLElement>) => openLink(event, result.link)}
              >
                <OpenInNewIcon fontSize='inherit' />
              </IconButton>
            )}
          </Stack>
        </div>
      </CardActionArea>
    </Card>
  );
}
