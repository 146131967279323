import ExternalLink from "./ExternalLink";

export const PresentationTooltip = (
  <span>
    A presentation is a series of screens with words and pictures on them. It is a useful tool for
    presenting information to people in an orderly way, often by listing your main points along with
    diagrams or illustrations. A good presentation includes just enough content to help your
    listeners be engaged and remember what is most important. A good presentation avoids
    overwhelming or distracting your viewers with too much information.
    <br />
    <br />
    Presentations, also called "slides decks" or "Powerpoints", are used extensively during business
    meetings and by public speakers, and they can be displayed on a large screen, screen-shared over
    a remote video call, or simply shared as a file for people to open on their own time.
    <br />
    <br />
    You can create and view slide decks in different programs, including Microsoft{" "}
    <ExternalLink link='https://www.customguide.com/powerpoint' text='Powerpoint' /> and Google{" "}
    <ExternalLink
      link='https://support.google.com/a/users/answer/9313043?hl=en&ref_topic=9296555'
      text='Slides'
    />
    .
  </span>
);

export const DocumentTooltip = (
  <span>
    A document is a bunch of pages that you can write words on. You can also include images, charts,
    and graphs in a document, but its main function is to hold information about a topic or set of
    topics. Documents are useful for storing a lot of information in one place, and they are often
    many pages long. They can be used for reports on a particular topic, documentation about a
    subject or process, or summaries of events.
    <br />
    <br />
    Here are a few common kinds of documents:
    <br />
    <br />
    Report - An account of something that you have observed, heard, done, researched, or
    investigated
    <br />
    Contract - An agreement (often about employment, selling something, or buying something) that is
    meant to be kept by multiple parties and able to be enforced
    <br />
    Documentation - Official information or evidence to provide a record of something
    <br />
    Policy - A principle or set of principles that a government, business, or person use as a basis
    for making decisions
    <br />
    Procedure - An established or official set of steps to do something
    <br />
    <br />
    You can create and view documents in different programs, including Microsoft{" "}
    <ExternalLink link='https://www.customguide.com/word' text='Word' /> and Google{" "}
    <ExternalLink link='https://support.google.com/a/users/answer/9282664' text='Docs' />.
  </span>
);

export const SpreadsheetTooltip = (
  <span>
    A spreadhseet is a special type of document that contains a bunch of cells in rows and columns.
    It's a good place to organize and make sense of a bunch of data. Most of the data in
    spreadsheets will be numbers or text, but you can also write special functions that allow you to
    perform calculations on groups of the data. Programs for spreadsheets allow you to make special
    tables or charts and graphs from the data once it is organized.
    <br />
    <br />
    Businesses use spreadsheets to organize and make sense of data that they use, as well as create
    charts and graphs to display the data in a way that is useful to make decisions. The tables,
    charts, and graphs can be used in a document or presentation to share with others.
    <br />
    <br />
    You can create and view spreadsheets in different programs, including Microsoft{" "}
    <ExternalLink link='https://www.customguide.com/excel' text='Excel' /> and Google{" "}
    <ExternalLink link='https://support.google.com/a/users/answer/9282959' text='Sheets' />.
  </span>
);

export const WebsiteTooltip = (
  <span>
    A website is a collection of web pages managed by a company, organization, or person. A webpage
    is a document on the internet that you can view with a web browser. Web pages each have a URL or
    address, which is where you go in a browser to view the page. Each page has a different URL.
    Pages can have words, pictures, videos, and links. Links are ways to get to other web pages.
    <br />
    <br />
    Websites can do a lot of different things. They can advertise products or companies, provide
    information about different subjects, allow people to buy or sell things, and much more.
    <br />
    <br />
    You can create and view sites in different programs, like Google{" "}
    <ExternalLink link='https://support.google.com/a/users/answer/9282722' text='Sites' />.
  </span>
);
