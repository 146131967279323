import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import logo_full from "../../assets/pbeej-logo-new.svg";
import { useMediaQuery } from "react-responsive";
import { SelectedTags } from "../../helpers/discover";
import Tag from "../../models/Tag";
import AvailabilityFilters from "../filters/AvailabilityFilters";
import AgeRange from "../filters/AgeRange";
import CategoryType from "../filters/CategoryType";
import Keywords from "../filters/Keywords";
import StatusFilters from "../filters/StatusFilters";
import { BooleanFilter } from "../../helpers/discover";

export const filterDrawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerBody = styled("div")(({ theme }) => ({
  padding: "0 15px 0 15px",
}));

interface FilterDrawerProps {
  open: boolean;
  onClose: () => void;
  keywordsReceived: Tag[];
  setMultiSelectTags: Function;
  contentTypesReceived: Tag[];
  ageRangesReceived: Tag[];
  selectedTags: SelectedTags;
  setBooleanFlags: (filters: BooleanFilter[]) => void;
}

export default function FilterDrawer({
  onClose,
  open,
  ageRangesReceived,
  contentTypesReceived,
  keywordsReceived,
  selectedTags,
  setMultiSelectTags,
  setBooleanFlags,
}: FilterDrawerProps) {
  const isMobile = useMediaQuery({
    query: "(max-width: 985px)",
  });

  return (
    <Drawer
      sx={{
        width: filterDrawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: filterDrawerWidth,
          boxSizing: "border-box",
          overflowX: "hidden",
        },
      }}
      variant={isMobile ? "temporary" : "persistent"}
      anchor='left'
      onClose={onClose}
      open={open}
    >
      <DrawerHeader className={"px-2"}>
        <Box sx={{ flexGrow: 1 }}>
          <img src={logo_full} alt={"pbeej logo svg"} />
        </Box>

        <IconButton onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider sx={{ margin: "10px -15px 10px -15px" }} />
      <DrawerBody>
        <Typography variant='subtitle1' sx={{ mt: 1, fontWeight: 500 }}>
          Status
        </Typography>
        <StatusFilters
          myActive={selectedTags.myActive}
          myWorkInProgress={selectedTags.myWorkInProgress}
          myPrivate={selectedTags.myPrivate}
          setBooleanFlags={setBooleanFlags}
        />
        <Typography variant='subtitle1' sx={{ mt: 1, fontWeight: 500 }}>
          Keywords
        </Typography>
        <Keywords
          allTags={keywordsReceived}
          selectedTags={selectedTags.keywords}
          setTags={setMultiSelectTags}
        />
        <Typography variant='subtitle1' sx={{ mt: 1, fontWeight: 500 }}>
          Type
        </Typography>
        <CategoryType
          tags={contentTypesReceived}
          selectedTags={selectedTags.type}
          setTags={setMultiSelectTags}
        />
        <Typography variant='subtitle1' sx={{ mt: 1, fontWeight: 500 }}>
          Age Range
        </Typography>
        <AgeRange
          allTags={ageRangesReceived}
          selectedTags={selectedTags.ageRange}
          setTags={setMultiSelectTags}
        />
        <AvailabilityFilters
          freeFlag={selectedTags.freeFlag}
          pbeejOnlyFlag={selectedTags.pbeejOnlyFlag}
          parentResourceFlag={selectedTags.parentResourceFlag}
          setBooleanFlags={setBooleanFlags}
        />
      </DrawerBody>
    </Drawer>
  );
}
