import { useEffect, useMemo, useState } from "react";
import { Select, MenuItem, FormHelperText } from "@mui/material";
import { getCollaboratorsList, getContentStatusLabels } from "../../helpers/helpers";
import Collaborator from "../../models/Collaborator";
import { AddFormValues } from "./AddContent";
import { Controller, UseFormReturn } from "react-hook-form";
import EditCollaborators from "../shared/EditCollaborators";

export default function ContentStatus({
  form,
  setFormError,
}: {
  form: UseFormReturn<AddFormValues>;
  setFormError: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [contentStatusLabels, setContentStatusLabels] = useState<string[]>([
    form.getValues("statusCode"),
  ]);
  const [availableCollaborators, setAvailableCollaborators] = useState<Collaborator[]>([]);
  const [statusCodeSpy, setStatusCodeSpy] = useState<string>(form.getValues("statusCode"));
  const [collaboratorsSpy, setCollaboratorsSpy] = useState<Collaborator[]>(
    form.getValues("collaborators"),
  );

  useEffect(() => {
    getContentStatusLabels().then((result) => {
      setContentStatusLabels(result);
    });

    getCollaboratorsList().then((response) => {
      setAvailableCollaborators(response);
    });
  }, []);

  const invalidPrivate = useMemo(
    () => statusCodeSpy === "Private" && (collaboratorsSpy ?? []).length > 0,
    [statusCodeSpy, collaboratorsSpy],
  );
  const invalidShareWithSome = useMemo(
    () => statusCodeSpy === "Share with Some" && (collaboratorsSpy ?? []).length === 0,
    [statusCodeSpy, collaboratorsSpy],
  );

  useEffect(() => {
    setFormError(invalidPrivate || invalidShareWithSome);
  }, [setFormError, invalidPrivate, invalidShareWithSome]);

  return (
    <>
      <Controller
        control={form.control}
        name='statusCode'
        rules={{ required: true, validate: () => !(invalidPrivate || invalidShareWithSome) }}
        render={({ field: { value, onChange } }) => (
          <>
            <Select
              color={"info"}
              id='statusCode'
              value={value}
              label='Status'
              variant='standard'
              onChange={(event) => {
                setStatusCodeSpy(event.target.value);
                onChange(event);
              }}
            >
              {contentStatusLabels.map((statusOption) => (
                <MenuItem key={statusOption} value={statusOption}>
                  {statusOption}
                </MenuItem>
              ))}
            </Select>
            {invalidPrivate && (
              <FormHelperText id='invalidPrivate' style={{ color: "red" }}>
                Please remove all collaborators in order to make content Private
              </FormHelperText>
            )}
            {invalidShareWithSome && (
              <FormHelperText id='invalidShareWithSome' style={{ color: "red" }}>
                Please invite collaborators in order to Share with Some
              </FormHelperText>
            )}
          </>
        )}
      />

      <Controller
        control={form.control}
        name='collaborators'
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <EditCollaborators
            availableTags={availableCollaborators}
            selectedTags={value}
            setSelectedTags={(event) => {
              setCollaboratorsSpy(event);
              onChange(event);
            }}
          />
        )}
      />
    </>
  );
}
