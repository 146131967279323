import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { searchProjects, ProjectMetadata, ProjectSearchRequest } from "../../helpers/projects";
import ProjectPreviewCard from "./ProjectPreviewCard";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router";
import { useAppContext } from "../util/AppContextHook";

interface ProjectsListProps {
  hasFilterPanelFiltersApplied: boolean;
  searchText?: string;
  addingItem?: boolean;
}

export interface AddToProjectResourceType {
  project: string;
  projectId: number;
}

const defaultVisibleProjectCount = 4;

// TODO: Have a nice ui for an empty project list. Point them to the add dropdown to create their first project
// TODO: Have a nice ui for no projects matching the search term.
export default function ProjectsList(props: ProjectsListProps) {
  const [projects, setProjects] = useState<ProjectMetadata[]>([]);
  const [expandedOverride, setExpandedOverride] = useState<boolean | undefined>(undefined);
  const [showMore, setShowMore] = useState(false);
  const [addToProjectSelection, setAddToProjectSelection] = useState<
    AddToProjectResourceType | undefined
  >(undefined);
  const {
    addingToProject,
    setAddingToProject,
    activeResourceName,
    projectUpdatesExist,
    setProjectUpdatesExist,
  } = useAppContext();

  const location = useLocation();

  useEffect(() => {
    const params: ProjectSearchRequest = {
      searchText: props.searchText,
    };

    searchProjects(params).then((data) => {
      setProjects(data);
      setProjectUpdatesExist(false);
    });
  }, [props.searchText, projectUpdatesExist, setProjectUpdatesExist, location]);

  const expanded =
    (typeof expandedOverride === "undefined"
      ? !props.hasFilterPanelFiltersApplied
      : expandedOverride) || addingToProject;

  const handleShowMoreToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowMore(!showMore);
  };

  const handleAccordionClick = () => {
    setExpandedOverride(!expanded);
  };

  const visibleProjects = showMore
    ? projects.length
    : Math.min(projects.length, defaultVisibleProjectCount);

  const cancelAddToProject = () => {
    setAddingToProject(false);
  };

  return (
    <Accordion
      expanded={expanded}
      onClick={addingToProject ? null : handleAccordionClick}
      sx={{ borderRadius: "4px", marginBottom: 1 }}
    >
      <AccordionSummary
        id='panel-header'
        aria-controls='panel-content'
        expandIcon={addingToProject ? <CloseIcon /> : <ExpandMoreIcon />}
        onClick={addingToProject ? () => cancelAddToProject() : () => {}}
      >
        <Stack width={"100%"}>
          {addingToProject ? (
            <Typography variant={"h6"} align='left'>
              Adding <b>{activeResourceName}</b> to a Project!
            </Typography>
          ) : (
            <Typography variant='h6' align='left'>
              Projects
            </Typography>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={addingToProject ? { backgroundColor: "#e8e8e8" } : {}}>
        <Grid container spacing={4}>
          {projects.slice(0, visibleProjects).map((project) => {
            return (
              <ProjectPreviewCard
                key={project.id}
                project={project}
                addToProjectSelection={addToProjectSelection}
                setAddToProjectSelection={setAddToProjectSelection}
              />
            );
          })}
        </Grid>
        {projects.length > defaultVisibleProjectCount ? (
          <Box sx={{ paddingTop: "10px" }}>
            <Button onClick={handleShowMoreToggle} size='small'>
              {showMore ? "Show Less" : "Show More..."}
            </Button>
          </Box>
        ) : undefined}
      </AccordionDetails>
    </Accordion>
  );
}
