import AppsIcon from "@mui/icons-material/Apps";
import ArticleIcon from "@mui/icons-material/Article";
import MicIcon from "@mui/icons-material/Mic";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import LinkIcon from "@mui/icons-material/Link";
import ScienceIcon from "@mui/icons-material/Science";
import HikingIcon from "@mui/icons-material/Hiking";
import StyleIcon from "@mui/icons-material/Style";
import CasinoIcon from "@mui/icons-material/Casino";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import WorkIcon from "@mui/icons-material/Work";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import PhotoIcon from "@mui/icons-material/Photo";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import TerminalIcon from "@mui/icons-material/Terminal";
import GridOnIcon from "@mui/icons-material/GridOn";
import BookIcon from "@mui/icons-material/Book";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import LanguageIcon from "@mui/icons-material/Language";
import DescriptionIcon from "@mui/icons-material/Description";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

export function getContentActionText(content: string) {
  switch (content) {
    case "App":
      return `Download ${content}`;
    case "Article":
    case "Book":
    case "Textbook":
      return `Read ${content}`;
    case "Audio Channel/Podcast":
    case "Audio File":
    case "Podcast":
      return `Listen to ${content}`;
    case "Dropbox Link":
    case "Website":
    case "Project":
      return `Go to ${content}`;
    case "Experiment":
      return `Perform ${content}`;
    case "Field Trip":
      return `Go on ${content}`;
    case "Flashcards":
    case "General / Misc":
    case "Kit":
    case "List":
    case "Poster / Graphic":
    case "Presentation":
    case "Software":
    case "Spreadsheet":
    case "Document":
      return `View ${content}`;
    case "Game":
      return `Play ${content}`;
    case "Video":
    case "Video Channel":
      return `Watch ${content}`;
    case "Parent Help":
      return `Go To ${content}`;
    default:
      return null;
  }
}

export default function getContentTypeIcon(content: string) {
  switch (content) {
    case "App":
      return <AppsIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Article":
      return <ArticleIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Audio Channel/Podcast":
      return <MicIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Audio File":
      return <VolumeUpIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Book":
      return <BookIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Collection":
      return <PermMediaIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Dropbox Link":
      return <LinkIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Experiment":
      return <ScienceIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Field Trip":
      return <HikingIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Flashcards":
      return <StyleIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Game":
      return <CasinoIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "General / Misc":
      return <MiscellaneousServicesIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Kit":
      return <WorkIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "List":
      return <FormatListBulletedIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Parent Help":
      return <SupervisorAccountIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Podcast":
      return <PodcastsIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Poster / Graphic":
      return <PhotoIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Presentation":
      return <SlideshowIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Project":
      return <AccountTreeIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Software":
      return <TerminalIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Spreadsheet":
      return <GridOnIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Textbook":
      return <MenuBookIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Video":
      return <OndemandVideoIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Video Channel":
      return <VideoLibraryIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Website":
      return <LanguageIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    case "Document":
      return <DescriptionIcon sx={{ fontSize: "16px", margin: "auto !important" }} />;
    default:
      return null;
  }
}
